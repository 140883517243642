import React from 'react'
import "./Errorbar.css";

const Errorbar = () => {
    return (
        <div className="containerError textShadow">
            <div className="firstError">&#10006;</div><div className="secondError">Błąd podczas wysyłania wiadomości email !</div>
        </div>
    );
};
export default Errorbar;