import React from 'react'
import "./Buttonteleport.css";

const Buttonteleport = () => {
    return (
        <div className='positionTeleport'>
            <a href="https://www.sciware.pl/" target="_blank" rel="noreferrer" className="constantTeleport">Strona Główna</a>
        </div>
    );
};
export default Buttonteleport;