import React from 'react'
import './App.css';
import logo from './logo.png';
import Warningbar from './snackbar/Warningbar';
import Errorbar from './snackbar/Errorbar';
import Successbar from './snackbar/Successbar';
import Buttonteleport from './snackbar/Buttonteleport';

function App() {
  const [name, setName] = React.useState("")
  const [contact, setContact] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [showWarningbar, setShowWarningbar] = React.useState(false)
  const [showErrorbar, setShowErrorbar] = React.useState(false)
  const [showLoginPanel, setShowLoginPanel] = React.useState(true)

  const onSend = async (e) => {
    e.preventDefault()
    if (name === "" || contact === "") {
      setShowWarningbar(true)
      setTimeout(() => {
        setShowWarningbar(false);
      }, 3000)
      return
    }
    setIsLoading(true)
    try {
      let res = await fetch("/api/mail.php", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          contact
        })
      })
      res = await res.json()
      if (res.success) {
        setShowLoginPanel(false)
      } else {
        setShowErrorbar(true)
        setTimeout(() => {
          setShowErrorbar(false);
        }, 3000)
      }
    } catch (e) {
      if (name === "" || contact === "") {
        setIsLoading(false)
        return
      } else {
        setShowErrorbar(true)
        setTimeout(() => {
          setShowErrorbar(false);
        }, 3000)
      }
    }
    setIsLoading(false)
  }

  return (
    <div className="App">
      <header className="App-header"></header>
      <div className="Container">
        <div>
          <img src={logo} alt="logo" className="Logo"></img>
        </div>
        <div className="First">
          <span>Profesjonalne systemy i rozwiązania informatyczne</span>
        </div>
        <div className="Second">
          <span>Darmowa konsultacja i projekt specjalnie dla Coraz Lepszych Przedsiębiorców</span>
        </div>
        <div className="Third">
          <span>Zostaw kontakt i dowiedz się jak dedykowane oprogramowanie pomoże Ci rozwinąć swoje przedsiębiorstwo!</span>
        </div>
        <div className="Fourth">
          {showLoginPanel
            ? <form className="horizontal_login">
              <input className="name" placeholder="Imię/Nazwa" value={name} onChange={(e) => setName(e.target.value)}></input>
              <input className="telephone" placeholder="Numer telefonu/Email" value={contact} onChange={(e) => setContact(e.target.value)}></input>
              <button disabled={isLoading} className="button" onClick={onSend}>{!isLoading ? "Zapisz" : "Wysyłanie..."}</button>
              {showWarningbar ? <Warningbar /> : ""}
              {showErrorbar ? <Errorbar /> : ""}
            </form>
            : <from className="successBar">
              <Successbar />
              <Buttonteleport />
            </from>
          }


        </div>
      </div>
      <a title='Strona główna' href="https://www.sciware.pl/" target="_blank" rel="noreferrer" className="teleport">&#10140;</a>
    </div>
  );
}

export default App;
