import React from 'react'
import "./Successbar.css";

const Successbar = () => {
    return (
        <div className="containerSuccess textShadow">
            <div className="firstSuccess">&#10004;</div><div className="secondSuccess">Wiadomość wysłana.</div>
        </div>
    );
};
export default Successbar;