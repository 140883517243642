import React from 'react'
import "./Warningbar.css";

const Warningbar = () => {
    return (
        <div className="container textShadow">
            <div className="first">&#9888;</div><div className="second">Wypełnij wszystkie pola !</div>
        </div>
    );
};
export default Warningbar;